import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
  setBackgroundImage,
  printClasses,
  getTrack,
  urlForTraining,
  links,
  trainingKits,
} from '../Helpers'

import { isMemberType } from './parts-for-affiliates/AffiliatesHelpers'

import {
  useStatus,
  useProfile,
  useDashboard,
  useDeleteScore,
} from '../context/atomContext'

import { useIsPermission } from '../hooks/FeatureHooks'

import { SocialContext } from '../context/socialContext'

import AppLayout from '../components/layout/Layout'

import Avatar from '../components/elements/Avatar'
import Button from '../components/elements/Button'
import Icon from '../components/elements/Icon'
import IconText from '../components/elements/IconText'
import Dialog from '../components/elements/Dialog'

import DashboardSlider from '../components/molecules/DashboardSlider'
import DashboardTrainingCard from '../components/molecules/DashboardTrainingCard'
import SubscribeDialog from '../components/molecules/SubscribeDialog'
import Panel10kSubscribed from '../components/molecules/Panel10kSubscribed'
import Promotion from '../components/molecules/Promotion'
import PromotionSkeleton from '../components/molecules/PromotionSkeleton'
import EditDeleteDialog from '../components/molecules/EditDeleteDialog'
import FeaturedProducts from '../components/molecules/FeaturedProducts'
import ConfirmDeleteScore from '../components/molecules/ConfirmDeleteScore'
import WorkoutStats from '../components/molecules/WorkoutStats'

import DashboardItem from '../components/structures/DashboardItem'
import DetailsBlock from '../components/structures/DetailsBlock'
import UserProfileRow from '../components/structures/UserProfileRow'
import TrainingJournalRow from '../components/structures/TrainingJournalRow'
import VideoPlayer from '../components/structures/VideoPlayer'
import WorkshopItem from '../components/structures/WorkshopItem'

import AtomDetails from '../components/screens/Dashboard/AtomDetails'
import AffiliateDetails from '../components/screens/Dashboard/AffiliateDetails'
import PersistDetails from '../components/screens/Dashboard/PersistDetails'
import Nutrition from '../components/screens/Nutrition'

function Dashboard() {
  const history = useHistory()

  const closedTrackDetails =
    window.localStorage.getItem('DASHBOARD-track-details-closed') === 'true'

  const { openProfile } = useContext(SocialContext)
  const status = useStatus()
  const [showSubDialog, setShowSubDialog] = useState(undefined)
  const [showAffiliateDialog, setShowAffiliateDialog] = useState(undefined)
  const [show10kDialog, setShow10kDialog] = useState(undefined)
  const [show10kPanel, setShow10kPanel] = useState(undefined)
  const [showDashVideo, setShowDashVideo] = useState(false)
  const [selectedProgramTrack, setSelectedProgramTrack] = useState({})
  const [selectedWorkshop, setSelectedWorkshop] = useState(undefined)
  const [workshopOpen, setWorkshopOpen] = useState(false)

  const { data: myProfile, isLoading: isLoadingProfile } = useProfile()
  const isStatsVisible = useIsPermission(['beta', 'statsVisible'])
  const {
    data: dashboardData,
    isLoading: isLoadingDashboard,
    isError: isDashboardError,
  } = useDashboard(true)

  const serverPromoData = Array.isArray(dashboardData?.value?.promotions)
    ? dashboardData?.value?.promotions
    : [dashboardData?.value?.promotions]

  const promotionsData = !isLoadingDashboard ? serverPromoData : []

  // have to double load the data to account for swiper behavior
  const workshops = !isLoadingDashboard
    ? [...dashboardData?.value?.workshops, ...dashboardData?.value?.workshops]
    : undefined

  const products = dashboardData?.value?.featuredProducts || {}

  const myAffiliate = dashboardData?.value?.affiliate

  const trainingDataAtomic = dashboardData?.value?.training?.atomic
  const trainingDataAffiliate = dashboardData?.value?.training?.affiliate
  const trainingDataPersist = dashboardData?.value?.training?.persist

  const journalData = dashboardData?.value?.journal

  // Items for edit/delete mode
  const [showScoreTools, setShowScoreTools] = useState(false)
  const dismissShowScoreTools = () => setShowScoreTools(false)

  const [showConfirmDeleteScore, setShowConfirmDeleteScore] = useState(false)
  const dismissConfirmDeleteScore = () => setShowConfirmDeleteScore(false)
  const displayConfirmDeleteScore = () => {
    setShowConfirmDeleteScore(showScoreTools)
    dismissShowScoreTools()
  }
  const deleteScore = useDeleteScore()

  // const event10k2022Subscription = myProfile?.value?.registrations?.event10k2022
  const event10k2023Subscription = myProfile?.value?.registrations?.event10k2023

  const showEvent10kRow = event10k2023Subscription
  const showEventsSection = showEvent10kRow

  const atomicSubscription = myProfile?.value?.subscriptions?.atomic
  const manualSubscription = myProfile?.value?.subscriptions?.manual
  const persistSubscription = myProfile?.value?.subscriptions?.persist
  const persistNutritionSubscription =
    myProfile?.value?.subscriptions?.persist_nutrition

  const upsell = myProfile?.value?.upsell

  const todaysAtomicTraining = trainingDataAtomic
  const todaysPersistTraining = trainingDataPersist && {
    ...trainingDataPersist,
    track: 'persist',
  }

  const affiliateSubscription = isMemberType(myProfile, 'coach')
  const affiliateMemberSubscription = isMemberType(myProfile, 'member')
  const todaysAffiliateTraining = trainingDataAffiliate

  setBackgroundImage('')

  const hasJournalData = journalData?.length > 0

  const loading = isLoadingProfile || isLoadingDashboard

  const notLoading = !isLoadingProfile && !isLoadingDashboard

  const userRow = (
    <UserProfileRow
      key="userRow"
      user={myProfile?.value}
      isMe
      avatarSize={3}
      openProfile={() => openProfile(status?.user?.id)}
      cnames="dashboard--my-user-row"
      action={
        myAffiliate?.value?.image && (
          <Avatar size={3} image={myAffiliate?.value?.image} />
        )
      }
      showWorkoutStats
    />
  )

  const hero = (
    <header
      key="hero"
      className={printClasses([
        'dashboard--promotion',
        'rpm-hero',
        showDashVideo ? 'is-playing' : 'is-not-playing',
      ])}
    >
      {showDashVideo && (
        <VideoPlayer
          videoId={showDashVideo}
          showWorkoutOverlay={false}
          autoPlay
          aspectRatio="4:5"
          id="dashboardPlayer"
          resume={false}
        />
      )}

      {!showDashVideo && (
        <Swiper
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 5000 }}
          centeredSlides
          watchSlidesProgress
          spaceBetween={0}
          rewind
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {promotionsData.length === 0 && (
            <SwiperSlide key="skeleton">
              <PromotionSkeleton />
            </SwiperSlide>
          )}
          {promotionsData?.map(promo => (
            <SwiperSlide key={promo.id}>
              {() => (
                <Promotion promotion={promo} showDashVideo={setShowDashVideo} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </header>
  )

  const heels = (
    <>
      <SubscribeDialog
        isOpen={showSubDialog}
        handleDismiss={() => setShowSubDialog(false)}
      />

      <EditDeleteDialog
        isOpen={showScoreTools}
        dismiss={dismissShowScoreTools}
        displayConfirmDeleteScore={displayConfirmDeleteScore}
        displayEditScoreForm={() => {
          history.push(urlForTraining(showScoreTools))
        }}
      />

      <ConfirmDeleteScore
        isOpen={showConfirmDeleteScore}
        action={() => {
          dismissConfirmDeleteScore()
          deleteScore.mutate({
            id: showConfirmDeleteScore.id,
            track: showConfirmDeleteScore.track,
          })
        }}
        dismissAction={dismissConfirmDeleteScore}
      />

      <Dialog
        isOpen={showAffiliateDialog}
        handleDismiss={() => setShowAffiliateDialog(false)}
        header={myProfile?.value?.affiliate?.name || getTrack('affiliate').name}
        cnames="dashboard--dialog dashboard--dialog--element"
      >
        <section>
          <h3>Stay tuned!</h3>
          <p> Your gym’s programming will be here 05.01.22!</p>
        </section>
      </Dialog>

      <Dialog
        isOpen={show10kDialog}
        handleDismiss={() => setShow10kDialog(false)}
        header="10K Challenge"
        cnames="dashboard--dialog dashboard--dialog--10k"
      >
        <section>
          <p>
            <b>10,000 Double-unders. 30 Days.</b>
          </p>
          <p>
            Register for the 10K Challenge and join RPM and the Iron Compass in
            our mission to build brighter futures for kids through fitness and
            action sports opportunities.
          </p>
          <p>
            <Button
              label="Register"
              kind="accent"
              gtm="Atom-Dashboard-Join-Dialog"
              onClick={() => {
                setShow10kDialog(false)
                window.open(links.REGISTER_10K, '_blank')
              }}
            />
          </p>
        </section>
      </Dialog>

      <Panel10kSubscribed
        isOpen={show10kPanel}
        handleDismiss={() => setShow10kPanel(false)}
      />
    </>
  )

  const todaysAtomicMedia = todaysAtomicTraining?.media
  const todaysPersistMedia = todaysPersistTraining?.media
  const todaysAffiliateMedia = todaysAffiliateTraining?.media

  const atomDetails = (
    <AtomDetails
      onClick={() => {
        if (atomicSubscription)
          history.push(
            urlForTraining({
              ...todaysAtomicTraining,
              program: 'atomic',
              kit: Object.keys(dashboardData?.value.training.atomic.workout)[
                selectedProgramTrack.atomic
              ],
            }),
          )
        else setShowSubDialog('atomic')
      }}
      track={
        dashboardData?.value?.training?.atomic?.workout &&
        Object.keys(dashboardData?.value.training.atomic.workout)[
          selectedProgramTrack.atomic
        ]
      }
    />
  )

  const persistDetails = (
    <PersistDetails
      track={
        dashboardData?.value?.training?.persist?.workout &&
        trainingKits(
          'persist',
          Object.keys(dashboardData?.value.training.persist.workout),
        ).map(kit => kit.id)[selectedProgramTrack.persist]
      }
      tourEnabled={persistSubscription}
    />
  )

  const affiliateDetails = (
    <AffiliateDetails
      onClick={() => {
        history.push(
          urlForTraining({
            ...todaysAffiliateTraining,
            track: 'affiliate',
            kit: Object.keys(dashboardData?.value.training.affiliate.workout)[
              selectedProgramTrack.affiliate
            ],
          }),
        )
      }}
    />
  )

  const detailsByProgram = {
    atomic: atomDetails,
    persist: persistDetails,
    affiliate: affiliateDetails,
  }

  return (
    <AppLayout
      name="dashboard"
      isLoading={loading}
      isError={isDashboardError}
      errorAction={() => window.location.reload()}
      aside={Object.keys(products).map(trackId => {
        const track = getTrack(trackId)
        return (
          <FeaturedProducts
            cnames="dashboard--featured-products training--products"
            label={`${track.name} Exclusive Deals`}
            products={products[trackId]}
            titleCnames="dashboard--section--title"
            key={trackId}
          />
        )
      })}
      userRow={userRow}
      hero={hero}
      heels={heels}
    >
      <article className="app-content dashboard--sections">
        {isStatsVisible && (
          <DetailsBlock
            label="Workout Stats"
            tooltip={
              <>
                <p>
                  <strong>Total workouts</strong> - Total workouts logged
                  (all-time) across the entire Atom platform.
                </p>
                <p>
                  <strong>Streak</strong> - Consecutive weeks of training
                  without missing your workout goal. Your default goal is 3
                  workouts/wk, which is editable in{' '}
                  <Link to="/profile" className="underline">
                    profile
                  </Link>
                  .
                </p>
              </>
            }
            collapsible={false}
            cnames="rpm-description training--info-block training--notes workout-stats"
          >
            <WorkoutStats />
          </DetailsBlock>
        )}

        <section className="dashboard--section dashboard--section--training">
          <h4 className="dashboard--section--title text--caps">
            Daily Training
          </h4>

          {upsell?.atomic && (
            <DashboardItem
              kind="button"
              onClick={() => setShowSubDialog('atomic')}
              cnames="journal--row dashboard--dt dashboard--dt--atomic ish"
              title={getTrack('atomic').name}
              lines={[getTrack('atomic').description]}
              altItem={<Icon name="lock-alt" type="fal" cnames="ghost" />}
              rowMarkerConfig={{
                line1: dayjs().format('MMM'),
                line2: dayjs().format('DD'),
                theme: 'inactive',
              }}
              details={
                <div className="dashboard--details--padding">{atomDetails}</div>
              }
            />
          )}

          {false && affiliateMemberSubscription && todaysAffiliateTraining && (
            <DashboardItem
              linkTo={urlForTraining({
                ...todaysAffiliateTraining,
                track: 'affiliate',
              })}
              title={
                myProfile?.value?.affiliate?.name || getTrack('affiliate').name
              }
              lines={[
                todaysAffiliateTraining?.media?.main?.custom_fields
                  ?.coaches && (
                  <IconText
                    icon={{ name: 'coach', type: 'svg' }}
                    text={todaysAffiliateMedia?.main?.custom_fields.coaches}
                    cnames="text--bold"
                  />
                ),
              ]}
              rowMarkerConfig={{
                line1: dayjs(todaysAffiliateTraining?.date).format('MMM'),
                line2: dayjs(todaysAffiliateTraining?.date).format('DD'),
              }}
              details={affiliateDetails}
            />
          )}

          {upsell?.persist && (
            <DashboardItem
              kind="button"
              onClick={() => setShowSubDialog('persist')}
              cnames="journal--row dashboard--dt dashboard--dt--atomic ish"
              title={getTrack('persist').name}
              lines={[
                <IconText
                  icon={{ name: 'coach', type: 'svg' }}
                  text="Marcus Filly"
                  cnames="text--bold"
                />,
              ]}
              altItem={<Icon name="lock-alt" type="fal" cnames="ghost" />}
              rowMarkerConfig={{
                line1: dayjs().format('MMM'),
                line2: dayjs().format('DD'),
                theme: 'inactive',
              }}
              details={
                <div className="dashboard--details--padding">
                  {persistDetails}
                </div>
              }
            />
          )}

          {false && persistSubscription && todaysPersistTraining && (
            <DashboardItem
              linkTo={urlForTraining(todaysPersistTraining)}
              title={getTrack('persist').name}
              lines={[
                todaysPersistTraining?.media?.main?.custom_fields?.coaches && (
                  <IconText
                    icon={{ name: 'coach', type: 'svg' }}
                    text={todaysPersistMedia.main.custom_fields.coaches}
                    cnames="text--bold"
                  />
                ),
              ]}
              rowMarkerConfig={{
                line1: dayjs(todaysPersistTraining?.date).format('MMM'),
                line2: dayjs(todaysPersistTraining?.date).format('DD'),
              }}
              details={persistDetails}
            />
          )}

          {false && atomicSubscription && todaysAtomicTraining && (
            <DashboardItem
              linkTo={urlForTraining(todaysAtomicTraining)}
              title={getTrack('atomic').name}
              lines={[
                todaysAtomicTraining?.media?.main?.custom_fields?.coaches && (
                  <IconText
                    icon={{ name: 'coach', type: 'svg' }}
                    text={todaysAtomicMedia?.main?.custom_fields.coaches}
                    cnames="text--bold"
                  />
                ),
              ]}
              rowMarkerConfig={{
                line1: dayjs(todaysAtomicTraining?.date).format('MMM'),
                line2: dayjs(todaysAtomicTraining?.date).format('DD'),
              }}
              details={atomDetails}
            />
          )}
          {dashboardData?.value?.training &&
            Object.keys(dashboardData?.value?.training).map(program => {
              const allowedKits = Object.keys(
                dashboardData?.value.training[program].workout,
              )
              const t = trainingKits(program, allowedKits).map(kit => kit.id)
              const tracks = [...t, ...t]
              return (
                <React.Fragment key={`${program}`}>
                  <DashboardSlider
                    defaultItem={tracks.findIndex(
                      item =>
                        item ===
                        window.localStorage.getItem(`kitForTrack-${program}`),
                    )}
                    onActiveIndexChange={swip => {
                      setSelectedProgramTrack(data => ({
                        ...data,
                        [program]: swip.realIndex % t.length,
                      }))
                    }}
                    details={
                      <DetailsBlock
                        collapsible
                        defaultOpen={!closedTrackDetails}
                        lines={0}
                        buttonCnames="fg--brand"
                        scrollTo={`dashboard-slider-${program}`}
                        onToggle={val =>
                          window.localStorage.setItem(
                            'DASHBOARD-track-details-closed',
                            !val,
                          )
                        }
                      >
                        {detailsByProgram[program]}
                      </DetailsBlock>
                    }
                    className={`dashboard-slider-${program}`}
                  >
                    {tracks.map((track, index) => (
                      <SwiperSlide key={String(`${program}-${track}-${index}`)}>
                        <DashboardTrainingCard
                          linkTo={urlForTraining({
                            ...dashboardData?.value.training[program],
                            track: program,
                            kit: track,
                          })}
                          track={track}
                          program={getTrack(program).name}
                          rowMarkerConfig={{
                            line1: dayjs(
                              dashboardData?.value.training[program]?.date,
                            ).format('MMM'),
                            line2: dayjs(
                              dashboardData?.value.training[program]?.date,
                            ).format('DD'),
                          }}
                          coaches={
                            dashboardData?.value.training[program]?.media?.main
                              ?.custom_fields.coaches
                          }
                          image={
                            dashboardData?.value.training[program].media[track]
                              ? dashboardData?.value.training[program].media[
                                  track
                                ].poster
                              : dashboardData?.value.training[program].media
                                  .main?.poster
                          }
                        />
                      </SwiperSlide>
                    ))}
                  </DashboardSlider>
                </React.Fragment>
              )
            })}
        </section>

        {persistNutritionSubscription && (
          <section className="dashboard--section dashboard--section--nutrition">
            <h4 className="dashboard--section--title text--caps">
              <span>Nutrition</span>

              <Link to="/persist/nutrition">
                <span className="text--caps ish text--700">See All</span>{' '}
                <Icon name="chevron-right" type="far" size="lg" cnames="ish" />
              </Link>
            </h4>

            <Nutrition collapsible={false}>
              Message your coach and find nutrition resources all in one place.{' '}
              <Link to="/persist/nutrition">Click Here!</Link>
            </Nutrition>
          </section>
        )}

        {!isLoadingDashboard && workshops?.length > 0 && (
          <section className="dashboard--section dashboard--section--workshops">
            <h4 className="dashboard--section--title text--caps">
              <span>Workshops</span>

              <Link to="/workshops">
                <span className="text--caps ish text--700">See All</span>{' '}
                <Icon name="chevron-right" type="far" size="lg" cnames="ish" />
              </Link>
            </h4>
            <DashboardSlider
              onActiveIndexChange={swip => {
                setSelectedWorkshop(swip.realIndex)
              }}
              details={
                selectedWorkshop !== undefined && (
                  <WorkshopItem
                    workshop={workshops[selectedWorkshop]}
                    key={workshops[selectedWorkshop].id}
                    setShowSubDialog={setShowSubDialog}
                    showHero={false}
                    detailsOpen={workshopOpen}
                    toggleDetails={setWorkshopOpen}
                  />
                )
              }
            >
              {workshops.map((ws, index) => (
                <SwiperSlide key={String(`workshop-${ws.id}-${index}`)}>
                  <WorkshopItem
                    workshop={ws}
                    key={ws.id}
                    setShowSubDialog={setShowSubDialog}
                    showStats={false}
                  />
                </SwiperSlide>
              ))}
            </DashboardSlider>
          </section>
        )}

        {affiliateSubscription && todaysAffiliateTraining && (
          <section className="dashboard--section dashboard--section--affiliates">
            <h4 className="dashboard--section--title text--caps">
              WUWO Affiliate
            </h4>
            <DashboardItem
              linkTo="/affiliate/coaching"
              title="Session Plan"
              rowMarkerConfig={{
                line1: dayjs(todaysAffiliateTraining?.date).format('MMM'),
                line2: dayjs(todaysAffiliateTraining?.date).format('DD'),
              }}
            />
            <DashboardItem
              linkTo="/affiliate/overview"
              title="Monthly Overview"
              rowMarkerConfig={{
                line2: <Icon type="fas" name="list" />,
              }}
            />
          </section>
        )}

        {showEventsSection && (
          <section className="dashboard--section dashboard--section--events">
            <h4 className="dashboard--section--title text--caps">Events</h4>
            {showEvent10kRow && (
              <>
                {event10k2023Subscription && (
                  <DashboardItem
                    kind="link"
                    onClick={() => setShow10kPanel(true)}
                    linkTo="/events/10k"
                    title={getTrack('event10k').name}
                    lines={[getTrack('event10k').description]}
                    rowMarkerConfig={{
                      image: '/app/images/events/10k-icon.png',
                      theme: 'event',
                    }}
                    cnames={printClasses([
                      'journal--row',
                      'dashboard--event dashboard--event-10k',
                    ])}
                    styles={{ '--row-align-items': 'center' }}
                  />
                )}
              </>
            )}
          </section>
        )}

        <section className="dashboard--section dashboard--section--journal">
          <h4 className="dashboard--section--title text--caps">
            <span>Training Journal</span>

            {hasJournalData && (
              <Link to="/journal">
                <span className="text--caps ish text--700">See All</span>{' '}
                <Icon name="chevron-right" type="far" size="lg" cnames="ish" />
              </Link>
            )}
          </h4>

          {hasJournalData ? (
            <>
              <div
                className="rpm-subheader journal--subheader"
                style={{ '--subheader-bg': 'var(--white)' }}
              >
                {manualSubscription && (
                  <Link
                    to={{
                      pathname: '/journal',
                      state: { showScoreForm: true },
                    }}
                  >
                    <IconText
                      icon={{
                        name: 'square-plus',
                        type: 'fas',
                        size: 'xl',
                      }}
                      text="Add Manual Activity"
                    />
                  </Link>
                )}
                <span className="spacer" />
                <span>Result</span>
              </div>

              <ul className="rpm-data-list dashboard--journal--results">
                {journalData.map(
                  (row, index) =>
                    index < 3 && (
                      <TrainingJournalRow
                        row={{ ...row, userId: status?.user?.id }}
                        key={String(`${row.date}-${row.track}-${row.id}`)}
                        openManualEdit={() =>
                          history.push('/journal', {
                            showScoreForm: true,
                            commentItem: row,
                          })
                        }
                        disabled={
                          !(
                            myProfile?.value?.workshops?.[row.training?.slug] ||
                            myProfile?.value?.subscriptions?.[row.track]
                          )
                        }
                        action={
                          row.status === 'draft' ? setShowScoreTools : undefined
                        }
                      />
                    ),
                )}
              </ul>
            </>
          ) : (
            notLoading &&
            !hasJournalData && (
              <div className="rpm-block dashboard--journal--no-results">
                <p>
                  No results yet. Once you log a workout, you&apos;ll find your
                  results here.
                </p>
              </div>
            )
          )}
        </section>
      </article>
    </AppLayout>
  )
}

export default Dashboard
