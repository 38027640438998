import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SwiperSlide } from 'swiper/react'

import Button from '../elements/Button'
import { PlayIcon } from '../elements/Icon'
import Markdown from '../elements/Markdown'

import VideoPlayer from '../structures/VideoPlayer'

import DashboardSlider from '../molecules/DashboardSlider'
import DashboardTrainingCard from '../molecules/DashboardTrainingCard'

import {
  urlForTraining,
  getTrack,
  setBackgroundImage,
  kitLabel,
} from '../../Helpers'

import { useDashboard } from '../../context/atomContext'

type PersistTourSummaryProps = {
  responses: Object,
  onBack: () => void,
}

export const persistTrackData = {
  PERSIST_PUMP_HYPERTROPHY: {
    answerId: 'persist_quiz_pre_pump_lift',
    image: '/app/images/persist/quiz/Pump_Lift_5X.jpg',
    time: '60-75 minutes',
    days: '5 days per week',
    subTitle: 'Build Visible Muscle',
    text: '<ul><li>Free weights & gym machine options</li><li>Extra hypertrophy - no conditioning</li><li>Includes Short on Time options</li></ul>',
    videoId: 'atom.6345956721112',
  },
  PERSIST_PUMP_HYPERTROPHY_3DAY: {
    answerId: 'persist_quiz_pre_pump_lift',
    image: '/app/images/persist/quiz/Pump_Lift_3X.jpg',
    time: '60-75 minutes',
    days: '3 days per week',
    subTitle: 'Build Visible Muscle',
    text: '<ul><li>Free weights & gym machine options</li><li>Extra hypertrophy - no conditioning</li><li>Includes Short on Time options</li></ul>',
    videoId: 'atom.x5d1a14usfiz0xztnklx6ise',
  },
  PERSIST_PUMP_HYPERTROPHY_4DAY: {
    answerId: 'persist_quiz_pre_pump_lift',
    image: '/app/images/persist/quiz/Pump_Lift_4x.jpg',
    time: '60-75 minutes',
    days: '4 days per week',
    subTitle: 'Build Visible Muscle',
    text: '<ul><li>Free weights & gym machine options</li><li>Extra hypertrophy - no conditioning</li><li>Includes Short on Time options</li></ul>',
    videoId: 'atom.xis2gypz943nhewilxxbr6f2',
  },
  PERSIST_PUMP: {
    answerId: 'persist_quiz_pre_pump_condition',
    image: '/app/images/persist/quiz/Pump_5X.jpg',
    time: '60-75 minutes',
    days: '5 days per week',
    subTitle: 'Build Visible Muscle',
    text: '<ul><li>Full equipment (free weights)</li><li>Includes muscle-building conditioning</li><li>Includes Short on Time options</li></ul>',
    videoId: 'atom.6345956346112',
  },
  PERSIST_PUMP_3DAY: {
    answerId: 'persist_quiz_pre_pump_condition',
    image: '/app/images/persist/quiz/Pump_3X.jpg',
    time: '60-75 minutes',
    days: '3 days per week',
    subTitle: 'Build Visible Muscle',
    text: '<ul><li>Full equipment (free weights)</li><li>Includes muscle-building conditioning</li><li>Includes Short on Time options</li></ul>',
    videoId: 'atom.kh2asxg5uyiepsdyq0kced7a',
  },
  PERSIST_PERFORM: {
    answerId: 'persist_quiz_pre_perform',
    image: '/app/images/persist/quiz/Perform.jpg',
    time: '60-75 minutes',
    days: '5 days per week',
    subTitle: 'Athleticism & Performance',
    text: '<ul><li>Full equipment & olympic lifts</li><li>Includes Short on Time options</li></ul>',
    videoId: 'atom.6345955845112',
  },
  PERSIST_PILLARS: {
    answerId: 'persist_quiz_pre_pillars',
    image: '/app/images/persist/quiz/Pump_4X.jpg',
    time: '<60 minutes',
    days: '4 days per week',
    subTitle: 'Look Good, Move Well',
    text: '<ul><li>Full equipment(simple setups)</li><li>FBB Foundations</li></ul>',
    videoId: 'atom.egeh7fmgsi74c7e7iq9nvdg6',
  },
  PERSIST_MINIMALIST: {
    answerId: 'persist_quiz_pre_minimalist',
    image: '/app/images/persist/quiz/Minimalist.jpg',
    time: '<60 minutes',
    days: '4 days per week',
    subTitle: 'Anywhere, Anytime',
    text: '<ul><li>Low equipment</li><li>Great for travel</li></ul>',
    videoId: 'atom.6345957812112',
  },
}

export const persistTrackAnswers = {
  PERSIST_PUMP: {
    persist_quiz_1: ['persist_quiz_1_answer2'],
    persist_quiz_2: ['persist_quiz_2_answer1'],
    persist_quiz_3: ['persist_quiz_3_answer2'],
    persist_quiz_6: ['persist_quiz_6_answer3'],
  },
  PERSIST_PILLARS: {
    persist_quiz_1: ['persist_quiz_1_answer3'],
    persist_quiz_3: ['persist_quiz_3_answer2'],
    persist_quiz_6: ['persist_quiz_6_answer2'],
  },
  PERSIST_PUMP_3DAY: {
    persist_quiz_1: ['persist_quiz_1_answer2'],
    persist_quiz_2: ['persist_quiz_2_answer1'],
    persist_quiz_3: ['persist_quiz_3_answer2'],
    persist_quiz_6: ['persist_quiz_6_answer1'],
  },
  PERSIST_PUMP_HYPERTROPHY: {
    persist_quiz_1: ['persist_quiz_1_answer2'],
    persist_quiz_2: ['persist_quiz_2_answer2'],
    persist_quiz_3: ['persist_quiz_3_answer2'],
    persist_quiz_6: ['persist_quiz_6_answer3'],
  },
  PERSIST_PUMP_HYPERTROPHY_4DAY: {
    persist_quiz_1: ['persist_quiz_1_answer2'],
    persist_quiz_2: ['persist_quiz_2_answer2'],
    persist_quiz_3: ['persist_quiz_3_answer2'],
    persist_quiz_6: ['persist_quiz_6_answer2'],
  },
  PERSIST_PUMP_HYPERTROPHY_3DAY: {
    persist_quiz_1: ['persist_quiz_1_answer2'],
    persist_quiz_2: ['persist_quiz_2_answer2'],
    persist_quiz_3: ['persist_quiz_3_answer2'],
    persist_quiz_6: ['persist_quiz_6_answer1'],
  },
  PERSIST_PERFORM: {
    persist_quiz_1: ['persist_quiz_1_answer1'],
    persist_quiz_3: ['persist_quiz_3_answer2'],
    persist_quiz_6: ['persist_quiz_6_answer3'],
  },
  PERSIST_MINIMALIST: {
    persist_quiz_3: ['persist_quiz_3_answer1'],
  },
}

export const computeScore = responses => {
  const tracks = Object.keys(persistTrackAnswers)

  const totals = {}
  tracks.forEach(trackName => {
    // console.log('TRACK:', trackName)
    const trackAnswers = Object.keys(persistTrackAnswers[trackName])
    let totalMatch = 0
    trackAnswers.forEach(trackAnswer => {
      // console.log('Answer:', trackAnswer)
      const answerKey = persistTrackAnswers[trackName][trackAnswer]
      if (answerKey.length === responses[trackAnswer]?.length) {
        let hasAll = true
        for (let i = 0; i < answerKey.length; i += 1) {
          if (!responses[trackAnswer].includes(answerKey[i])) {
            hasAll = false
          }
        }
        if (hasAll) totalMatch += 1
      }
    })
    totals[trackName] = totalMatch / trackAnswers.length
  })
  // console.log(totals)
  const score = Object.keys(totals).reduce((prev, curr) => {
    if (totals[curr] > totals[prev]) return curr
    return prev
  })
  localStorage.setItem('kitForTrack-persist', score)
  return score
}

function PersistTourSummary(props: PersistTourSummaryProps) {
  const { responses, onBack } = props
  const [currentSelection, setCurrentSelection] = useState(undefined)
  const [playTrackVideo, setPlayTrackVideo] = useState(false)
  const history = useHistory()

  const { data: dashboardData } = useDashboard(true)

  const preSelectedQuestion = responses?.persist_quiz_pre
  const preSelectedResponse =
    preSelectedQuestion?.length && preSelectedQuestion[0]

  const preSelectedKit = Object.keys(persistTrackAnswers).findIndex(
    val => persistTrackData[val]?.answerId === preSelectedResponse,
  )

  const kit = computeScore(responses)
  setBackgroundImage('')

  const recommendedKit =
    preSelectedKit !== -1
      ? Object.keys(persistTrackAnswers)[preSelectedKit]
      : kit

  const tracks = Object.keys(persistTrackAnswers)
  const doubledTracks = [...tracks, ...tracks]

  return (
    <section className="persist-tour-summary">
      <DashboardSlider
        defaultItem={
          preSelectedKit !== -1
            ? preSelectedKit
            : Object.keys(persistTrackAnswers).findIndex(val => val === kit)
        }
        onActiveIndexChange={swip => {
          setCurrentSelection(tracks[swip.realIndex % tracks.length])
          setPlayTrackVideo(false)
        }}
        swiperProps={{
          breakpoints: {
            1024: {
              slidesPerView: 3.5,
            },
          },
        }}
        details={
          <div className="persist-tour-summary--details flex--auto-space">
            <div
              style={{
                width: '100%',
              }}
            >
              <h1 className="text--caps">
                {`${getTrack('persist').name} - ${kitLabel(
                  currentSelection || kit,
                )}`}
              </h1>
              <div className="flex--auto-spread persist-tour-summary--details--subtitle">
                <i>{persistTrackData[currentSelection || kit].subTitle}</i>
              </div>
              <div className="flex--auto-gap persist-tour-summary--details--time">
                <div>{persistTrackData[currentSelection || kit].time}</div> /{' '}
                <span>{persistTrackData[currentSelection || kit].days}</span>
              </div>
              <Markdown>
                {persistTrackData[currentSelection || kit].text}
              </Markdown>
              <Button
                onClick={() =>
                  history.push(
                    {
                      pathname: '/persist/training',
                      search: `kit=${currentSelection || kit}`,
                    } || '/dashboard',
                  )
                }
                cnames="persist-tour-summary--details--button"
              >
                Start Training
              </Button>
            </div>
          </div>
        }
      >
        {doubledTracks?.map((kitId, index) => (
          <SwiperSlide key={String(`${kitId}-${index}`)}>
            {({ isActive }) => {
              if (isActive && playTrackVideo === kitId)
                return (
                  <div className="persist-tour-summary--video-player">
                    <VideoPlayer
                      videoId={persistTrackData[kitId].videoId}
                      key={persistTrackData[kitId].videoId}
                      autoPlay
                      showWorkoutOverlay={false}
                      aspectRatio="4:5"
                      resume={false}
                      onEnded={() => setPlayTrackVideo(false)}
                    />
                  </div>
                )

              return (
                <DashboardTrainingCard
                  linkTo={urlForTraining({
                    ...dashboardData?.value.training.persist,
                    track: 'persist',
                    kit: kitId,
                  })}
                  track={kitId}
                  program={getTrack('persist').name}
                  rowMarkerConfig={undefined}
                  coaches={
                    dashboardData?.value.training.persist.media?.main
                      ?.custom_fields?.coaches
                  }
                  headerText={
                    kitId === recommendedKit ? 'Recommended!' : undefined
                  }
                  image={
                    persistTrackData[kitId].image ||
                    dashboardData?.value.training.persist.media?.main?.poster
                  }
                  aspectRatio="4/5"
                  centerText={
                    persistTrackData[kitId].videoId && (
                      <Button
                        kind="text"
                        onClick={() => setPlayTrackVideo(kitId)}
                      >
                        <PlayIcon />
                      </Button>
                    )
                  }
                />
              )
            }}
          </SwiperSlide>
        ))}
      </DashboardSlider>
      <div className="rpm-block flex--auto-space">
        <Button kind="link" onClick={onBack} cnames="fg--light">
          Back
        </Button>
      </div>
    </section>
  )
}

export default PersistTourSummary
